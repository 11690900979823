

import {
  IonContent,
  IonHeader,
  IonPage,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonTitle,
  IonToolbar,
  IonTextarea,
  IonApp,
  IonIcon,
  IonLabel,
  IonSpinner,
  IonMenuToggle,
  IonFooter,
  IonButton,
  IonButtons,
  IonModal,
  IonGrid,
  IonAvatar,
  IonListHeader,
  IonItem,
  IonCol, IonBadge, IonRow,IonInput, alertController } from "@ionic/vue";

import { locationOutline, chevronDown, chevronForward, chevronBack, helpCircle, checkmark, close, search } from 'ionicons/icons';

import { useStore } from "vuex";

import { defineComponent, ref } from 'vue';

import { Drivers, Storage } from '@ionic/storage'

import { Geolocation } from '@capacitor/geolocation'

import fintailapi from '../api'



export default defineComponent({
  name: "canais",
  components: { IonContent,
  IonHeader,
  IonPage,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonTitle,
  IonToolbar,
  IonApp,
  IonIcon,
  IonLabel,
  IonSpinner,
  IonTextarea,
  IonMenuToggle,
  IonFooter,
  IonGrid,
  IonAvatar,
  IonListHeader,
  IonButton,
  IonButtons,
  IonModal,
  IonItem,
  IonCol, IonBadge, IonRow,IonInput, alertController },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => []
    }
  },
  emits: ["onDismiss", "onBack" , "onClick" ],
  methods: {


    showEndereco () {

      this.$emit("onClick", "{}")
      this.initLocalicao()
      this.isOpenLocation=true

    },




    autoSearch () {

      this.typing=true

      clearTimeout(this.ultima_pesquisa_auto);

      this.ultima_pesquisa_auto = setTimeout(()=>{

        this.autoComplete()

      }, 2000)

    },

    getNumber (endereco:any) {

      this.currentEnderecos.rua = this.selectedEnderecos.structured_formatting.main_text
      this.currentEnderecos.bairro = this.selectedEnderecos.structured_formatting.secondary_text
      this.isOpenGetNumber  = true

    },
    setAddress () {

      this.isEnviando = true
      const endereco = this.currentEnderecos

      endereco.first_name = this.store.state.user.info.first_name
      endereco.last_name = this.store.state.user.info.last_name

      const post = {

        novo: endereco,
        user_id: this.store.state.user.info.id

      }

      if(this.store.state.user.isGuest){

        this.isOpenGetNumber  = false
        this.$emit("onDismiss", "")

        return
      }

      fintailapi
      .post('/user/enderecos/novo', post)
      .then(async (response:any) => {

        const data = response.data

        if(data.success){

          //this.isOpenLocation   = false
          //this.isOpenSemEntrega = false
          //this.store.state.user.info.isOpenLocation = false
          this.isOpenGetNumber  = false

          this.$emit("onDismiss", "")

        }
        this.isEnviando = false
      })

    },
    getLoja (endereco:any) {

      if(endereco == ""){

        endereco = this.selectedEnderecos;
        endereco.numero = this.currentEnderecos.numero;
        endereco.complemento = this.currentEnderecos.complemento;

      }


      if((!endereco.numero || endereco.numero=="") && !endereco.getLocalicao){

        alertController.create({
                header: 'Localização',
                subHeader: ' Digite o número de sua residência ',
                message: 'Não identificamos o número para entrega',
                buttons: [
                  {
                      text: 'OK',
                      role: 'cancel',
                      handler: () => {

                      }
                  }
              ],
            }).then(res => {

              res.present();

            });

          return;
      }


      fintailapi
      .post('/endereco/lojas/', endereco)
      .then(async (response:any) => {

        const data = response.data

        if(data.entrega){

          await this.storage.set('location', data)

          this.currentEnderecos = data
          this.store.state.user.geolocation = data;

          this.isOpenLocation   = false
          this.isOpenSemEntrega = false
          this.store.state.user.info.isOpenLocation = false

          this.isOpenGetNumber  = false

          this.setAddress();


        }else{

          await this.storage.set('location', data)
          this.currentEnderecos = data
          this.isOpenSemEntrega = true;

          this.store.state.user.geolocation = data;
          this.$emit("onDismiss", "")

          this.isOpenGetNumber  = false

          this.setAddress();

        }


        fintailapi.defaults.headers.common['Store'] = data.codigo_loja;



      })

    },
    initLocalicao () {

      Geolocation.getCurrentPosition().then((resp) => {
        fintailapi
        .get('/localizacao/'+resp.coords.latitude+","+resp.coords.longitude+"/cidade")
        .then((response:any) => {

          const data = response.data
          if(data.success){
            this.localCity = data.cidade
          }

        })

      }).catch((error) => {})
    },
    getLocalicao () {

      Geolocation.getCurrentPosition().then((resp) => {
        //this.getLoja({ getLocalicao: true, latitude: resp.coords.latitude, longitude: resp.coords.longitude, numero: "" });
        this.autoCompleteGeoLocation(resp.coords.latitude+","+resp.coords.longitude)
      }).catch((error) => {

            alertController.create({
                header: 'Localização',
                subHeader: '',
                message: 'Não conseguimos determinar sua localização, verifique suas permissões de localização',
                buttons: [
                {
                      text: 'Configurações',
                      role: 'confirm',
                      handler: () => {
                        Geolocation.requestPermissions()
                      }
                  },
                  {
                      text: 'OK',
                      role: 'cancel',
                      handler: () => {

                      }
                  }
              ],
            }).then(res => {

              res.present();

            });


      })
    },
    autoCompleteGeoLocation (geo:any) {
      this.typing=false
      fintailapi
      .get('/localizacao/'+geo)
      .then((response:any) => {

        this.typing=false

        const data = response.data
        this.searchEnderecos = data.predictions

        if(data.predictions.length == 0)this.sem_resultados=true
        else this.sem_resultados=false

      })

    },
    autoComplete () {

      if(this.enderecoProcurar==""){

        this.typing=false
        this.searchEnderecos = []
        return
      }

      fintailapi
      .get('/endereco/'+this.enderecoProcurar+ " "+ this.localCity)
      .then((response:any) => {
        const data = response.data

        this.searchEnderecos = data.predictions

        if(data.predictions.length == 0){

          this.sem_resultados=true
          fintailapi
          .get('/endereco/'+this.enderecoProcurar)
          .then((response:any) => {
            const data = response.data

            this.typing=false
            this.searchEnderecos = data.predictions

            if(data.predictions.length == 0)this.sem_resultados=true
            else this.sem_resultados=false

          })
        }
        else {
          this.typing=false
          this.sem_resultados=false
        }
      })

    },
    voltar(){

      this.isOpenLocation = false
      this.isOpenSemEntrega = false
      this.store.state.user.info.isOpenLocation = false
      this.$emit("onBack", "{}")

    },
    goRetirada(){

      this.isOpenLocation = false
      this.isOpenSemEntrega = false
      this.isOpenGetNumber  = false
      this.store.state.user.info.isOpenLocation = false
      this.$emit("onDismiss", "")

    },
    selectAdress(endereco:any){
      this.selectedEnderecos = endereco;
      this.getNumber(endereco);
    }
  },
  data() {
    return {
      isOpenSemEntrega : false,
      isOpenLocation:false,
      isOpenGetNumber:false,
      localCity:"",
      isEnviando:false,
      typing:false,
      ultima_pesquisa_auto : 0,
      sem_resultados:false,
      safeTop:0,
      enderecoProcurar : "",
      searchEnderecos:[],
      currentEnderecos:{ rua:"", numero:"", bairro:"", complemento:"", first_name : "", last_name : ""},
      selectedEnderecos:{ structured_formatting : { main_text:"", secondary_text:"" }},
      isLast: false,
      locationOutline,chevronDown,chevronForward, helpCircle, checkmark, close,search,chevronBack,
    }
  },
  setup() {

    const store = useStore()

    const storage = new Storage({
      name: '__jauserve',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    });

    storage.create();
    
    return { store, storage }

  },
  mounted() {

    this.safeTop = this.store.state.safeTop

  

    this.$nextTick(function () {
      (async () => {

        const location = await this.storage.get('location')

        if (!location){
          this.isOpenLocation = true
          this.initLocalicao()
        }else{

          this.store.state.user.geolocation = location
          fintailapi.defaults.headers.common['Store'] = location.codigo_loja

        }
        
      })()
    })
  }
});
